import Model from 'flarum/Model';
import mixin from 'flarum/utils/mixin';
import computed from 'flarum/utils/computed';

export default class Tag extends mixin(Model, {
  name: Model.attribute('name'),
  slug: Model.attribute('slug'),
  description: Model.attribute('description'),

  color: Model.attribute('color'),
  backgroundUrl: Model.attribute('backgroundUrl'),
  backgroundMode: Model.attribute('backgroundMode'),
  icon: Model.attribute('icon'),

  position: Model.attribute('position'),
  parent: Model.hasOne('parent'),
  defaultSort: Model.attribute('defaultSort'),
  isChild: Model.attribute('isChild'),
  isHidden: Model.attribute('isHidden'),

  discussionCount: Model.attribute('discussionCount'),
  lastPostedAt: Model.attribute('lastPostedAt', Model.transformDate),
  lastPostedDiscussion: Model.hasOne('lastPostedDiscussion'),

  isRestricted: Model.attribute('isRestricted'),
  canStartDiscussion: Model.attribute('canStartDiscussion'),
  canAddToDiscussion: Model.attribute('canAddToDiscussion'),

  isPrimary: computed('position', 'parent', (position, parent) => position !== null && parent === false)  
}) {
    
    nestedPosition() { 
        if ( this.attribute('isChild') )
        {            
            return this.parent().nestedPosition() + this.attribute('position').toString().padStart(4, "0");
        }
        else
        {
            return this.attribute('position').toString().padStart(3, "0");
        }
    }
    
    root() {
        if ( this.attribute('isChild') )
        {            
            return this.parent().root();
        } 
        return this.attribute('position');
    }
    
    level() {
        if ( this.attribute('isChild') )
        {            
            return 1 + this.parent().level();
        } 
        return 1;
    }
    
    isDescendantOf(tag) {
        if ( this.attribute('isChild') )
        {
            if ( this.parent() === tag )
            {
                return true;
            }
            else
            {
                return this.parent().isDescendantOf(tag);
            }
        }
        return false;
    }
}
